import _ from 'lodash';
import { useAsyncMemo } from "use-async-memo";
import Model from "../../libs/ModelClass";
import { inputClasses } from "../../components/EntityTaxonomyForm";
import { withPrefix } from "../instance/utilsInstance";
import Select from 'react-select'
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from 'react';
import BadgeLoading from '../../components/ui/BadgeLoading';
import ContractsList from './ContractsList';
import { EntityShow } from '../entity/RouteEntityCrudShow';
import ObjectForm from '../entity/ObjectForm';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonCheckbox, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonBadge } from '@ionic/react';
import { getAmountNum, ShowAmount, toAmount } from '../../components/Form/utilsCurrency';
import toast from 'react-hot-toast';
import { RawInputInvoices } from './dataTypeInvoices';


const contractsEntitySlug = "contracts";
const contactsEntitySlug = "contacts";
const usersProfilesEntitySlug = "usersProfiles";
const creditCollectionsEntitySlug = "creditCollections";

export const afterRead = async (props, docData) => {
  if (!docData.id)  {
    docData.paidDate = docData.paidDate || dayjs().format('YYYY-MM-DD');
  }
  return docData;
};

// omit tmp vars beforeSave
export const beforeSave = async ({ instanceDoc, entityDoc }, formValues) => {
    // omit tmp vars beforeSave
    let data = { ...formValues };
    Reflect.deleteProperty(data, 'tmp');
    return data;
};

// update payments status to paid and set paidDate
export const afterSave = async (props, savedDoc) => {
  let {
    doc,
    formValues,
    isEdit,
    instance
  } = props;
  if (doc.data.paymentsIds?.length) {
    let PaymentsModel = Model.extend(withPrefix(instance, 'payments'));
    // TODO save with batch
    for (const paymentId of formValues.paymentsIds) {
      let paymentDoc = await PaymentsModel.findById(paymentId);
      if (paymentDoc) {
        paymentDoc.data.status = 'paid';
        paymentDoc.data.paidDate = formValues.paidDate;
        paymentDoc.data.collectionId = doc.id;
        await paymentDoc.save();
      }
    }
    toast.success('Pagos actualizados correctamente');
  }
};

export const FormPrependSelector = (props) => {
  let {
    instance,
    values,
    form
  } = props;

  const [ filterText, setFilterText ] = useState('');
  const [ innerValue, setInnerValue ] = useState();

  const allDocsByEntity = useAsyncMemo(async () => {
    // estructura de contractsDocs y contactsDocs: { id, data: { ... } }
    let contractsDocs = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ deleted: 'false' });
    let contactsDocs = await Model.extend(withPrefix(instance, contactsEntitySlug)).filterByAttributes({ deleted: 'false' });
    let usersProfilesDocs;
    if (instance === 'main') {
      usersProfilesDocs = await Model.extend(usersProfilesEntitySlug).filterByAttributes({ deleted: 'false' });
    }
    return {
      contracts: contractsDocs,
      contacts: contactsDocs,
      usersProfiles: usersProfilesDocs
    };
  }, []);

  const docsByEntity = useMemo(() => {
    if (allDocsByEntity) {
      let toCompare = filterText.toLowerCase();
      let contracts = allDocsByEntity.contracts.filter(doc => {
        if (!doc.data.name) {
          return true;
        }
        return doc.data.name?.includes(toCompare);
      });
      let contacts = allDocsByEntity.contacts.filter(doc => 
        doc.data.name?.toLowerCase().includes(toCompare)
        || doc.data.citizenNumber?.toString().includes(toCompare)
        || doc.data.email?.includes(toCompare)
        || doc.data.phone?.toString().includes(toCompare)
      );
      let usersProfiles;
      if (instance === 'main') {
        usersProfiles = allDocsByEntity.usersProfiles.filter(doc => 
          doc.data.name?.toLowerCase().includes(toCompare)
          || doc.data.email?.includes(toCompare)
          || doc.data.phone?.toString().includes(toCompare)
        );
      }
      return {
        contracts,
        contacts,
        usersProfiles
      };
    }
    return null;
  }, [allDocsByEntity, filterText]);

  const options = useMemo(() => {
    if (docsByEntity) {
      return [
        {
          label: 'Contraciones',
          options: docsByEntity.contracts.map(doc => ({
            label: `Contrato: ${doc.data.name || ''}`,
            value: doc.id,
            entity: 'contracts'
          }))
        },
        {
          label: 'Contactos',
          options: docsByEntity.contacts.map(doc => {
            let label = `Cliente: ${doc.data.name}`;
            if (doc.data.citizenNumber) {
              label += ` C.I.: ${doc.data.citizenNumber}`;
            }
            return {
              label,
              value: doc.id,
              entity: 'contacts'
            };
          })
        },
        {
          label: 'Usuarios',
          options: docsByEntity.usersProfiles.map(doc => {
            let label = `Usuario: ${doc.data.name}`;
            if (doc.data.email) {
              label += ` Email: ${doc.data.email}`;
            }
            if (doc.data.phone) {
              label += ` Tel: ${doc.data.phone}`;
            }
            return {
              label,
              value: doc.id,
              entity: 'usersProfiles'
            };
          })
        }
      ];
    }
    return [];
  }, [docsByEntity]);

  // fetch and set contact client and contractsList
  const currentData = useAsyncMemo(async () => {
    let contactClientDoc;
    let contractsDocsListOfContact;
    if (innerValue?.entity === 'contacts') {
      contactClientDoc = await Model.extend(withPrefix(instance, contactsEntitySlug)).findById(innerValue.value);
      contractsDocsListOfContact = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ customerId: innerValue.value, deleted: 'false' });
    }
    if (innerValue?.entity === 'contracts') {
      contractsDocsListOfContact = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ id: innerValue.value, deleted: 'false' });
      if (contractsDocsListOfContact?.length) {
        let contract = contractsDocsListOfContact[0];
        // get contact
        contactClientDoc = await Model.extend(withPrefix(instance, contactsEntitySlug)).findById(contract.data.customerId);
        // get userProfile
        if (!contactClientDoc && contract.data.userId) {
          contactClientDoc = await Model.extend(usersProfilesEntitySlug).findById(contract.data.userId);
        }
        if (contactClientDoc) {
          form.change('customerId', contactClientDoc.id);
        }
      }
    }
    if (innerValue?.entity === 'usersProfiles') {
      contactClientDoc = await Model.extend(usersProfilesEntitySlug).findById(innerValue.value);
      contractsDocsListOfContact = await Model.extend(withPrefix(instance, contractsEntitySlug)).filterByAttributes({ userId: innerValue.value, deleted: 'false' });
    }
    form.change('tmp.contactClientDoc', contactClientDoc);
    form.change('tmp.contractsDocsListOfContact', contractsDocsListOfContact);
    return {
      contactClientDoc,
      contractsDocsListOfContact
    };
  }, [innerValue]);
  
  const setValues = (selectedOption) => {
    setInnerValue(selectedOption);
    if (selectedOption.entity === 'contracts') {
      form.change('contractsIds', [selectedOption.value]);
      form.change('customerId', null); // set after fetch
      form.change('userId', null);
    }
    else if (selectedOption.entity === 'contacts') {
      form.change('contractsIds', null);
      form.change('customerId', selectedOption.value);
      form.change('userId', null);
    }
    else if (selectedOption.entity === 'usersProfiles') {
      form.change('contractsIds', null);
      form.change('customerId', null);
      form.change('userId', selectedOption.value);
    }
  };

  // console.log('innerValue', innerValue, currentData)

  return (
    <div className={inputClasses?.fieldContainer || ''}>
      {/* filter */}
      <div className={inputClasses?.fieldLabel}>
        <span>Clientes y Contrataciones</span>
      </div>

      {!docsByEntity ? (
        <div className="flex place-content-start font-brand-main">
          <BadgeLoading className="text-brand-dark" />
        </div>
      ) : (
        <Select
          options={options}
          isMulti={false}
          placeholder={`Seleccionar`}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'lightgray',
              primary: 'darkgray',
            },
          })}
          value={innerValue}
          onChange={setValues}
          isDisabled={false}
          menuPortalTarget={document.body}
          onInputChange={(text) => setFilterText(text)}
        />
      )}
    </div>
  );
};

const PaymentsByContractSelector = (props) => {
  let {
    contractsDocs,
    paymentsByContract,
    selectedIds,
    onChangeSelectedIds
  } = props;

  return (
    <div className="mt-6">
      {contractsDocs.map((contractDoc, index) => (
        paymentsByContract[contractDoc.data.id]?.length && (
          <IonCard key={contractDoc.id} className="mb-4 mx-0 px-0">
            <IonCardHeader>
              <IonCardTitle>Contrato: {contractDoc.data.name || ''}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonList>
                {paymentsByContract[contractDoc.data.id]?.map((paymentDoc, paymentIndex) => (
                  <IonItem key={paymentDoc.id} lines="none" button onClick={() => onChangeSelectedIds(paymentDoc.id)}>
                    {paymentDoc.data.status === 'pending' && (
                      <IonCheckbox
                        slot="start"
                        checked={selectedIds.includes(paymentDoc.id)}
                      />
                    )}
                    <div className="py-2 space-y-1.5">
                      <div>
                        <span className={`${inputClasses.fieldLabel} !inline mr-2`}>Num</span> {paymentDoc.data.num}
                        <div className="inline relative !ml-2 top-1">
                          {paymentDoc.data.status === 'pending' && dayjs(paymentDoc.data.dueDate).isBefore(dayjs()) && (
                            <IonBadge slot="end" color="danger">Vencido</IonBadge>
                          )}
                          {paymentDoc.data.status === 'pending' && (
                            <IonBadge slot="end" color="warning">Pendiente</IonBadge>
                          )}
                          {paymentDoc.data.status === 'paid' && (
                            <IonBadge slot="end" color="success">Pagado</IonBadge>
                          )}
                        </div>
                      </div>
                      <div>
                        <span className={`${inputClasses.fieldLabel} !inline mr-2`}>Monto</span>
                        <ShowAmount amount={paymentDoc.data.amount} />
                      </div>
                      <div>
                        <span className={`${inputClasses.fieldLabel} !inline mr-2`}>Fecha Vencimiento</span> {paymentDoc.data.dueDate}
                      </div>
                    </div>
                  </IonItem>
                ))}
              </IonList>
            </IonCardContent>
          </IonCard>
        )
      ))}
    </div>
  );
};

export const FormAppendRelatedData = (props) => {
  let {
     values,
     doc,
     instance,
     entityDoc,
     entitySlug,
     taxonomyTypesDocList,
     isAllowed,
     formProps,
     form
  } = props;
  const { contractsIds } = values;
  const { contactClientDoc, contractsDocsListOfContact } = (values.tmp || {});

  const setSelectedContractsIds = (selectedIds) => {
    form.change('contractsIds', selectedIds);
  };

  const contractsDocsSetter = (contractsDocs) => {
    form.change('tmp.contractsDocsListOfContact', contractsDocs);
  };

  return (
    <div className="">
      {/* Datos del contacto */}
      {contactClientDoc ? (
        <div className="mb-4">
          <div className={inputClasses?.fieldLabel}>
            <span>Contacto Cliente</span>
          </div>
          <EntityShow
            docId={contactClientDoc.id}
            entitySlug={withPrefix(instance, contactsEntitySlug)}
            showBtns={false}
          />
        </div>
      ) : null}

      {/* Lista de contratos del contacto */}      
      {contractsIds ? (
        <ContractsList
          {...props}
          showSelector={true}
          selectedIds={contractsIds}
          onChangeSelectedIds={setSelectedContractsIds}
          onAfterRead={contractsDocsSetter}
          customerId={values.customerId}
          userId={values.userId}
        />
      ) : null}
    </div>
  );
};

export const FormAppendColumnRight = (props) => {
  let {
     values,
     doc,
     instance,
     entityDoc,
     entitySlug,
     taxonomyTypesDocList,
     isAllowed,
     formProps,
     form,
     formApi
  } = props;

  const { contractsIds } = values;
  const { contractsDocsListOfContact } = (values.tmp || {});
  const [ selectedPaymentsIds, setSelectedPaymentsIds ] = useState(values.paymentsIds || []);
  const [ paymentsById, setPaymentsById ] = useState();
  const [ invoiceData, setInvoiceData ] = useState(values.invoiceData || {});

  // Obtener los pagos pendientes de los contratos seleccionados
  const paymentsByContract = useAsyncMemo(async () => {
    if (contractsIds?.length) {
      let docs = await Model.extend(withPrefix(instance, 'payments')).filterByAttributes({
        contractId: { in: contractsIds },
        status: 'pending',
        deleted: 'false'
      });
      const groupedDocs = _.groupBy(docs, 'data.contractId');
      // sort by 'data.dueDate'
      let selectedPaymentsIds = [];
      for (const contractId in groupedDocs) {
        groupedDocs[contractId] = _.sortBy(groupedDocs[contractId], 'data.dueDate');
        const pendingPayments = groupedDocs[contractId]
          .filter(paymentDoc =>
            paymentDoc.data.status === 'pending' &&
            dayjs(paymentDoc.data.dueDate).isBefore(dayjs())
          );
        selectedPaymentsIds = [
          ...selectedPaymentsIds,
          ...pendingPayments.map(paymentDoc => paymentDoc.id)
        ];
      }
      setSelectedPaymentsIds(selectedPaymentsIds);
      // payments by id
      let paymentsById = {};
      for (const paymentDoc of docs) {
        paymentsById[paymentDoc.id] = paymentDoc;
      }
      setPaymentsById(paymentsById);
      return groupedDocs;
    }
    return {};
  }, [contractsIds]);  

  // Manejar la selección de pagos
  const handleSelectPayment = (paymentId) => {
    let newSelectedPayments;
    if (selectedPaymentsIds.includes(paymentId)) {
      newSelectedPayments = selectedPaymentsIds.filter(id => id !== paymentId);
    } else {
      newSelectedPayments = [...selectedPaymentsIds, paymentId];
    }
    setSelectedPaymentsIds(newSelectedPayments);
  };

  useEffect(() => {
    formApi.change('paymentsIds', selectedPaymentsIds);
  }, [selectedPaymentsIds]);
  
  // Asignar en el formulario el monto total de los pagos seleccionados
  useEffect(() => {
    if (selectedPaymentsIds.length) {
      formApi.change('amountOfPayments', getPaymentsAmount(selectedPaymentsIds));
    } else {
      formApi.change('amountOfPayments', null);
    }
  }, [selectedPaymentsIds, paymentsById]);

  const amountFields = useRef({
    amountToPay: {
      type: 'numberAmount',
      name: 'Monto a pagar'
    }
  });

  function getPaymentsAmount (ids) {
    let totalAmount = 0;
    if (ids.length) {
      for (const id of ids) {
        const paymentDoc = paymentsById[id];
        if (paymentDoc) {
          totalAmount += getAmountNum(paymentDoc.data.amount);
        }
      }
    }
    return toAmount(totalAmount);
  };

  return (
    <div className="">
      {/* Formulario para el monto a pagar */}
      <div className={`${inputClasses.renderListContainer} !gap-0`}>
        <ObjectForm
          instance={instance}
          entitySlug={entitySlug}
          {...formProps}
          fields={amountFields.current}
        />
      </div>

      {/* Total de pagos seleccionados */}
      {selectedPaymentsIds.length > 0 && (
        <div className="mt-6">
          <span className={inputClasses.fieldLabel}>
            Total de pagos seleccionados
          </span>
          <ShowAmount amount={values.amountOfPayments} />
        </div>
      )}

      {_.size(paymentsByContract) && contractsDocsListOfContact?.length ? (<>
        {/* Factura */}
        <div className="mt-6">
          <span className={inputClasses.fieldLabel}>
            Factura
          </span>
          <RawInputInvoices
            instance={instance}
            entitySlug={entitySlug}
            value={invoiceData}
            onChange={setInvoiceData}
          />
        </div>

        {/* Lista de pagos */}
        <PaymentsByContractSelector
          contractsDocs={contractsDocsListOfContact}
          paymentsByContract={paymentsByContract}
          selectedIds={selectedPaymentsIds}
          onChangeSelectedIds={handleSelectPayment}
        />
      </>) : (
        <div className="mt-10">
          <p className="text-sm font-semibold">No hay pagos relacionados a los contratos seleccionados.</p>
        </div>
      )}
    </div>
  );
};

