import _ from 'lodash';
import InvoiceItemsTableLazy from './InvoiceItemsTableLazy';
import { getAmountNum, toAmount } from '../../components/Form/utilsCurrency';
import { useForm } from 'react-final-form';


const RenderForm = (props) => {
  const { value, onChange } = props;
  const { change } = useForm();

  const onChangeItems = (newItems) => {
    onChange(newItems);
    let newTotalPrice = _.sumBy(newItems, (item) => getAmountNum(item.totalPrice));
    change('totalPrice', toAmount(newTotalPrice || 0));
  };

  return (
    <InvoiceItemsTableLazy value={value} onChange={onChangeItems} />
  );
};

const RenderShow = (props) => {
  const { displayedValue } = props;

  console.log('invoice items', displayedValue);

  return (
    <div className="">
    </div>
  );
};

const dataTypePaymentConfig = {
  RenderForm,
  RenderShow
};

export default dataTypePaymentConfig;
