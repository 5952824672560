import _ from 'lodash';
import { RawInputObjectForm } from '../../components/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ObjectFormWrapped } from '../../components/Form/RawInputObjectForm';
import config from '../../config';
import { useEntityFullBySlug } from '../entity/Entity';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { withPrefix } from '../instance/utilsInstance';


let invoicesEntitySlug = config.modules.sales.invoicesEntitySlug;

export const RawInputInvoices = (props) => {
  const { instance, entitySlug, value, onChange } = props;
  const [formData, setFormData] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const { taxonomyTypesDocList } = useEntityFullBySlug({ entitySlug: withPrefix(instance, invoicesEntitySlug) });
  
  const taxonomyTypesDataList = useMemo(() => {
    if (taxonomyTypesDocList) {
      return _.map(taxonomyTypesDocList, 'data');
    }
  }, [taxonomyTypesDocList]);
  
  const requiredFields = useMemo(() => {
    let fields = [];
    taxonomyTypesDocList?.forEach((doc) => {
      if (doc.data.required) {
        fields.push(doc.data.nameSlug);
      }
    });
    return fields;
  }, [taxonomyTypesDocList]);

  const modalApiRef = useRef();
  const formApiRef = useRef();

  useEffect(() => {
    if (_.size(value)) {
      setFormData(value);
    } else {
      setFormData(null);
      formApiRef.current?.form.reset();
    }
  }, [value]);

  const assignDataAsValue = () => {
    modalApiRef.current?.close();
    onChange({ ...formData });
  };

  if (!taxonomyTypesDocList) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <div className="">
      <ObjectFormWrapped
        onFormChange={setFormData}
        fieldsRequired={requiredFields}
        setHasErrors={setHasErrors}
        apiGetter={(api) => (formApiRef.current = api)}
      >
        <RawInputObjectForm
          value={formData}
          showDataCard={true}
          handleSubmit={assignDataAsValue}
          baseFieldName="invoice"
          btnLabel="Guardar Factura"
          instance={instance}
          entitySlug={withPrefix(instance, invoicesEntitySlug)}
          title="Datos de la Factura"
          taxonomyTypesDataList={taxonomyTypesDataList}
          fieldsRequired={requiredFields}
          showSaveButton={true}
          saveBtnLabel="Guardar"
          saveBtnColor="primary"
          disableBtnSave={hasErrors}
          apiGetter={(api) => (modalApiRef.current = api)}
        />
      </ObjectFormWrapped>
    </div>
  );
};

const RenderShow = (props) => {
  const { displayedValue } = props;

  return (
    <div className="invoice-details">
      <h3>Detalle de la Factura</h3>
    </div>
  );
};

const dataTypeInvoices = {
  RenderForm: RawInputInvoices,
  RenderShow,
};

export default dataTypeInvoices;
