import _ from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { compileStringTemplate, lineBreaks } from '../../../libs/utils';
import { Background } from '../parts/PartBackground';
import { getImageURL, stackClasses } from '../../../libs/utils';
import { cardContainerStylesField } from "../parts/stylesFields";
import { CardContainer } from "../parts/PartCardContainer";
import { Image } from "../parts/PartImage";
import { Text } from "../parts/PartText";
import { Card } from "../parts/PartCard";

export default function () {
  return {
    type: 'image',
    variant: 'imageWithContent',
    template: CarouselWithContent,
    presets,
    params: {
      'imageFirst': {
        name: 'Imagen primero',
        type: 'boolean',
        defaultValue: true
      },
      'bgStyles': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'cardContainerStyles': cardContainerStylesField,
      'titleStyles': {
        name: 'Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'titleCardStyles': {
        name: 'Tarjeta del Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'subtitleStyles': {
        name: 'Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'subtitleCardStyles': {
        name: 'Tarjeta del Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'carouselCardStyles': {
        name: 'Tarjeta de Imagen',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'contentStyles': {
        name: 'Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'contentCardStyles': {
        name: 'Tarjeta del Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
    },
    // fields of text tab
    strings: {
      'items': {
        type: 'collection',
        name: 'Items del Carrusel',
        fields: {
          'images': {
            type: 'gallery',
            name: 'Imagenes',
            descriptionText: 'Se utilizara la primera imagen de la lista'
          },
          'title': {
            type: 'text',
            name: 'Título'
          },
          'subtitle': {
            type: 'text',
            name: 'Subtítulo'
          },
        }
      },
      'title': {
        type: 'text',
        name: 'Título'
      },
      'subtitle': {
        type: 'text',
        name: 'Subtítulo'
      },
      'content': {
        type: 'textArea',
        name: 'Contenido'
      },
    },
    partsMap: [
      {
        'label': 'Bloque',
        'background': ['bgStyles'],
        'card': ['cardContainerStyles']
      },
      {
        'label': 'Carrusel',
        'strings': ['items'],
        'card': ['carouselCardStyles']
      },
      {
        'label': 'Título',
        'strings': ['title'],
        'text': ['titleStyles'],
        'card': ['titleCardStyles']
      },
      {
        'label': 'Subtítulo',
        'strings': ['subtitle'],
        'text': ['subtitleStyles'],
        'card': ['subtitleCardStyles']
      },
      {
        'label': 'Contenido',
        'strings': ['content'],
        'text': ['contentStyles'],
        'card': ['contentCardStyles']
      },
    ]
  };
};

const imgUrl = (imageSrc) => {
  let url = _.isArray(imageSrc) ? imageSrc[0] : imageSrc;
  url = _.startsWith(url, 'http') ? url : getImageURL(url, 'xl', null);

  return url;
} 

const ItemCarrousel = ({ items, design = 'square', className }) => {
  let classes = {};
  if (design === 'square') {
    classes.img = "h-[450px] sm:h-[450px] lg:h-[450px] object-cover md:object-top";
  }
  if (design === 'horizontal') {
    classes.img = "h-[300px] sm:h-[300px] lg:h-[400px] object-cover md:object-top";
  }

  if (!items?.length) {
    return null;
  }
  return (
    <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} className={className}>
      {items?.map((item, index) => (
        <div key={index}>
          <img 
          src={imgUrl(item.images)} 
          alt={item.title}
          className={classes.img}
          />
            <div className="absolute bottom-4 sm:bottom-1 w-full mx-auto md:space-y-5 pb-24 md:pb-32 lg:pb-32 xl:pb-36">
              <h3 className='text-brand-primary-contrast font-brand-main font-black px-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl uppercase drop-shadow-md '>{lineBreaks(item.title)}</h3>
              <p className=' text-white font-black font-brand-main px-4 text-lg uppercase drop-shadow-md '>{lineBreaks(item.subtitle)}</p>
            </div>
        </div>
      ))}
    </Carousel>
  );
};

export function CarouselWithContent(props) {

  let {
    items,
    title,
    subtitle,
    content,
    bgStyles,
    specDesign,
    titleStyles,
    titleCardStyles,
    subtitleStyles,
    subtitleCardStyles,
    contentStyles,
    contentCardStyles,
    cardContainerStyles,
    carouselCardStyles,
    imageFirst,
  } = props;

  return (
    <Background params={bgStyles} classes={{ bgContainer: "snap-start" }} specDesign={specDesign}>
      <CardContainer params={cardContainerStyles} specDesign={specDesign}>
        <div className="">
          <div className="flex flex-col md:flex-row lg:items-center gap-4 sm:gap-8">
            {imageFirst ? (
              <>
                <Card params={carouselCardStyles} specDesign={specDesign} classes={{cardBgContainer: 'md:basis-1/2'}}>
                  {items?.length ? (
                    <ItemCarrousel 
                      items={items} 
                      design="horizontal" 
                      className="rounded-lg overflow-hidden shadow-lg"
                    />
                  ) : null}
                </Card>
                <Card params={contentCardStyles} specDesign={specDesign} classes={{cardBgContainer: 'md:basis-1/2 content-center text-left lg:text-base text-sm lg:text-start'}}>
                  {title ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[200ms]">
                      <Card params={titleCardStyles} specDesign={specDesign}>
                        <Text textParams={titleStyles} classDefault="text-black font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight" specDesign={specDesign}>
                          {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                        </Text>
                      </Card>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                  {subtitle ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      <Card params={subtitleCardStyles} specDesign={specDesign}>
                        <Text textParams={subtitleStyles} classDefault="pb-4 text-gray-400 font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight" specDesign={specDesign}>
                          {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                        </Text>
                      </Card>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                  {content ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      <Text textParams={contentStyles} classDefault="pb-6 font-brand-secondary text-xl md:text-[22px] 2xl:text-2xl leading-relaxed" specDesign={specDesign}>
                        {lineBreaks(compileStringTemplate(content, specDesign.stringsVars))}
                      </Text>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                </Card>
              </>
            ) : (
              <>
                <Card params={contentCardStyles} specDesign={specDesign} classes={{cardBgContainer: 'md:basis-1/2 content-center text-left lg:text-base text-sm lg:text-start'}}>
                  {title ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[200ms]">
                      <Card params={titleCardStyles} specDesign={specDesign}>
                        <Text textParams={titleStyles} classDefault="text-black font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight" specDesign={specDesign}>
                          {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                        </Text>
                      </Card>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                  {subtitle ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      <Card params={subtitleCardStyles} specDesign={specDesign}>
                        <Text textParams={subtitleStyles} classDefault="pb-4 text-gray-400 font-bold font-brand-main text-3xl lg:text-[42px] xl:text-[43px] 2xl:text-[45px] leading-tight" specDesign={specDesign}>
                          {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                        </Text>
                      </Card>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                  {content ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      <Text textParams={contentStyles} classDefault="pb-6 font-brand-secondary text-xl md:text-[22px] 2xl:text-2xl leading-relaxed" specDesign={specDesign}>
                        {lineBreaks(compileStringTemplate(content, specDesign.stringsVars))}
                      </Text>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                </Card>
                <Card params={carouselCardStyles} specDesign={specDesign} classes={{cardBgContainer: 'md:basis-1/2 order-last'}}>
                  {items?.length ? (
                    <ItemCarrousel 
                      items={items} 
                      design="horizontal" 
                      className="rounded-lg overflow-hidden shadow-lg"
                    />
                  ) : null}
                </Card>
              </>
            )}
          </div>
        </div>
      </CardContainer>
    </Background>
  );
}

const presets = {
  'base': {
    "bgStyles": [
      {
        "bgStyles": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "screen": "xxs",
        "marginX": 4,
        "marginY": 24
      },
      {
        "screen": "md",
        "marginX": 8,
        "marginY": 32
      },
      {
        "screen": "lg",
        "marginY": 40
      },
    ],
    "titleStyles": [
      {
        "textSize": "3xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "main"
      },
      {
        "textSize": "4xl",
        "screen": "md"
      },
      {
        "textSize": "5xl",
        "screen": "2xl"
      }
    ],
    "subtitleStyles": [
      {
        "textSize": "2xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "#9CA3AF",
        "font": "secondary"
      },
      {
        "textSize": "3xl",
        "screen": "md"
      },
      {
        "textSize": "4xl",
        "screen": "lg"
      },
    ],
    "contentStyles": [
      {
        "textSize": "base",
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "main"
      },
      {
        "textSize": "md",
        "screen": "md",
      },
      {
        "textSize": "lg",
        "screen": "xl"
      }
    ],
  },

  'base-details': {
    "bgStyles": [
      {
        "bgColor": "primary-shade",
        "bgVideoUrl": "",
        "type": "color",
        "bgOverlayAlpha": 0,
        "screen": "xxs"
      }
    ],
    "contentStyles": [
      {
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "primary-contrast",
        "fontVariations": [
          "normal"
        ],
        "font": "secondary",
        "textSize": "base"
      }
    ],
    "subtitleStyles": [
      {
        "screen": "xxs",
        "textColor": "secondary",
        "textSize": "base",
        "fontVariations": [
          "bold",
          "italic"
        ],
        "textAlign": "left",
        "font": "secondary"
      },
      {
        "screen": "md",
        "textSize": "base"
      },
      {
        "screen": "lg",
        "textSize": "base"
      }
    ],
    "subtitleCardStyles": [
      {
        "align": "left",
        "marginY": 2,
        "marginX": "none",
        "screen": "xxs"
      }
    ],
    "cardContainerStyles": [
      {
        "marginX": 12,
        "marginY": 24,
        "screen": "xxs"
      },
      {
        "marginX": 12,
        "screen": "md",
        "marginY": 32
      },
      {
        "marginX": 20,
        "screen": "lg",
        "marginY": 40
      },
    ],
    "titleStyles": [
      {
        "screen": "xxs",
        "textAlign": "left",
        "textSize": "3xl",
        "textColor": "primary-contrast",
        "font": "main",
        "fontVariations": [
          "bold"
        ]
      },
      {
        "textSize": "4xl",
        "screen": "md"
      },
      {
        "screen": "2xl",
        "textSize": "5xl"
      }
    ],
    "imageFirst": false
  },
};