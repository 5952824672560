import _ from 'lodash';
import { RawInputObjectForm } from '../../components/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ObjectFormWrapped } from '../../components/Form/RawInputObjectForm';
import { objectFormToTaxonomyTypes } from '../entity/ObjectForm';


const taxesFields = {
  exentas: {
    type: 'numberAmount',
    name: 'Exentas'
  },
  iva5: {
    type: 'numberAmount',
    name: 'IVA 5%'
  },
  iva10: {
    type: 'numberAmount',
    name: 'IVA 10%'
  }
};

const requiredFields = [];

const RenderForm = (props) => {
  const { instance, entitySlug, value, onChange } = props;
  const [ formData, setFormData ] = useState(false);
  const [ hasErrors, setHasErrors ] = useState(false);
  const taxesTaxonomyTypesDataList = useMemo(() => objectFormToTaxonomyTypes(taxesFields), []);
  const modalApiRef = useRef();
  const formApiRef = useRef();

  useEffect(() => {
    if (_.size(value)) {
      setFormData(value);
    } else {
      setFormData(null);
      formApiRef.current?.form.reset();
    }
  }, [value]);
  
  const assignDataAsValue = () => {
    modalApiRef.current?.close();
    onChange({ ...formData });
  }
  
  return (
    <div className="">
      <ObjectFormWrapped
        onFormChange={setFormData}
        fieldsRequired={requiredFields}
        setHasErrors={setHasErrors}
        apiGetter={(api) => formApiRef.current = api}
      >
        <RawInputObjectForm
          value={formData}
          showDataCard={true}
          handleSubmit={assignDataAsValue}
          baseFieldName="customer"
          btnLabel="Impuestos"
          instance={instance}
          entitySlug={entitySlug}
          title="Impuestos"
          taxonomyTypesDataList={taxesTaxonomyTypesDataList}
          fieldsRequired={requiredFields}
          showSaveButton={true}
          saveBtnLabel="Asignar"
          saveBtnColor="primary"
          disableBtnSave={hasErrors}
          apiGetter={(api) => modalApiRef.current = api}
        />
      </ObjectFormWrapped>
    </div>
  );
};

const RenderShow = (props) => {
  const { displayedValue } = props;

  console.log('invoice taxes', displayedValue);

  return (
    <div className="">
      Taxes
    </div>
  );
};

const dataTypeInvoiceITaxes = {
  RenderForm,
  RenderShow
};

export default dataTypeInvoiceITaxes;
