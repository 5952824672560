import { useEffect, useMemo, useRef, useState } from "react";
import slugify from "slugify";
import _ from "lodash";
import { useEntityFullBySlug } from "../../modules/entity/Entity";
import { useListDoc, viewInlineClasses } from "../../modules/entity/docCardsVariants";
import Model from "../../libs/ModelClass";
import { useModule } from "../../libs/ModuleContext";
import { IonButton, IonIcon, IonModal, IonHeader, IonToolbar, IonContent, IonItem, IonList, IonSearchbar, IonTitle } from "@ionic/react";
import { 
  documentTextOutline,
  menuOutline
} from 'ionicons/icons';
import { getJson } from "../../libs/utils";
import { RouteEntityCrudForm } from "../../modules/entity/RouteEntityCrudForm";
import BadgeLoading, { LoadingIcon } from "../ui/BadgeLoading";
import ObjectShow from "../../modules/entity/ObjectShow";


const RawInputSelectDocumentExtended = (props) => {
  let {
    value,
    onChange,
    instance,
    entitySlug,
    onAfterChange,
    title,
    fields,
    btnLabel = 'Seleccionar',
    queryGetter,
    fromJson,
    inheritFilter,
    values = {},
    form,
    taxonomyType,
    disabled
  } = props;

  console.log('select One', props)

  const elementId = useMemo(() => _.uniqueId('select-document-'), []);
  const [ selectedDoc, setSelectedDoc ] = useState();
  const [ textSearch, setTextSearch ] = useState();
  const [ docsList, setDocsList ] = useState([]);
  const [ showListPopover, setShowListPopover ] = useState(false);
  const [ showFormPopover, setShowFormPopover ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ docToUpdate, setDocToUpdate ] = useState();
  const { isAllowed } = useModule();
  
  const mode = fields ? 'object' : 'entity';
  const entitySpecs = useEntityFullBySlug({ entitySlug: mode === 'entity' ? entitySlug : null }); // void fetch on object mode
  const ListDoc = useListDoc({ entitySpecs, isAllowed });
  
  let defaultValueForm = {};
  let isDisabled = disabled || taxonomyType?.readOnly;
  let valuesToUse = form?.getState().values || values;
  let queryFilter = {};

  if (inheritFilter) {
    // set query filter map
    _.forEach(inheritFilter, (value, key) => {
      if (!_.isUndefined(valuesToUse[key]) && !_.isNull(valuesToUse[key])) {
        queryFilter[key] = valuesToUse[key];
      }
    });

    // apply inherit filters as default values for form
    _.forEach(inheritFilter, (value, key) => {
      defaultValueForm[key] = valuesToUse[key];
    });
  }

  const docsListFiltered = useMemo(() => {
    if (textSearch) {
      return docsList.filter(doc => doc.data.nameSlugToFilter.includes(slugify(textSearch, { lower: true, replacement: ' ' })));
    }
    return docsList;
  }, [textSearch, docsList]);

  const fetchData = async () => {
    const ExtendedModel = Model.extend(entitySlug);
    const docs = await queryGetter(ExtendedModel);
    let filteredDocs = [];
    docs.forEach((doc) => {
      if (!doc.data.deleted) {
        doc.data.nameSlugToFilter = slugify(doc?.data?.name || '', { lower: true, replacement: ' ' });
        filteredDocs.push(doc);
      }
    });
    filteredDocs = _.sortBy(filteredDocs, ['data.nameSlugToFilter']);
    if (value) {
      let selectedDoc = filteredDocs.find(doc => doc.id === value);
      setSelectedDoc(selectedDoc);
    }
    setDocsList(filteredDocs);
  };

  useEffect(() => {
    if (mode === 'entity' && entitySlug === 'entities') {
      fetchData();
    } 
    else if (mode === 'entity' && entitySlug) {
      fetchData();
    }
  }, [entitySlug, JSON.stringify(queryFilter)]);
  
  useEffect(() => {
    if (!value) {
      setSelectedDoc(null);
    }
    if (value && !selectedDoc) {
      fetchData();
    }
  }, [value]);

  if (!isAllowed(entitySlug, ['list', 'instance:list'])) {
    return null;
  }

  queryGetter = queryGetter || (async (ExtendedModel) => {
    setIsLoading(true);
    let docs;
    if (fromJson) {
      docs = await getJson(fromJson);
      docs = docs.map(json => new ExtendedModel(json));
    } else {
      // get only selected
      if (isDisabled && value) {
        const doc = await ExtendedModel.findById(value);
        docs = [ doc ];
      } 
      // filter
      else {
        // filter with inherit foreign keys
        if (inheritFilter && _.size(inheritFilter)) {
          docs = await ExtendedModel.filterByAttributes(queryFilter);
        } 
        // get all docs
        else {
          docs = await ExtendedModel.filterByAttributes({ deleted: 'false' });
        }
      }
    }
    setIsLoading(false);
    return docs;
  });

  const onAfterSaveForm = (savedItem) => {
    setSelectedDoc(savedItem);
    // add
    if (docToUpdate.id === 'new') {
      setDocsList([ savedItem, ...docsList ]);
      onChange(savedItem.id);
    }
    // update
    else {
      setDocsList(docsList.map(doc => doc.id === savedItem.id ? savedItem : doc));
      onChange(savedItem.id);
    }
    setDocToUpdate(null);
    setShowListPopover(false);
  };

  const onCloseForm = () => {
    setDocToUpdate(null);
  };

  const setOpenModal = () => {
    if (mode === 'entity') {
      setShowListPopover(true);
      setShowFormPopover(false);
    }
    else if (mode === 'object') {
      setShowFormPopover(true);
      setShowListPopover(false);
    }
  };

  const selectHandler = (doc) => () => {
    setSelectedDoc(doc);
    onChange(doc.id);
    onAfterChange && onAfterChange({ doc });
    setShowListPopover(false);
  };

  const onClosePopover = () => {
    setShowListPopover(false);
    setShowFormPopover(false);
  };
  
  const Card = ({ color, className, selectedDoc, doc }) => (
    <div className={`flex flex-row py-2 items-stretch w-full ${className}`}>
      {mode === 'entity' ? (
        <ListDoc 
          doc={doc}
          classes={{
            fieldLabel: color === 'medium' ? 'text-brand-medium-contrast' : '',
            fieldValue: 'text-inherit'
          }}
        />
      ) : null}

      {mode === 'object' ? (
        <ObjectShow
          instance={instance}
          entitySlug={entitySlug}
          value={doc}
          fields={fields}
          design="flat"
          classes={viewInlineClasses}
        />
      ) : null}
    </div>
  );

  return (<>
    {selectedDoc ? (
      <button onClick={setOpenModal} className="w-full">
        <div className="px-2 rounded-md overflow-hidden border border-gray-200">
          <Card {...{ selectedDoc, doc: selectedDoc }} />
        </div>
      </button>
    ) : (
      isLoading ? (
        <IonButton disabled={true} size="small" fill="outline" color="medium">
          <LoadingIcon className={'h-[18px] w-[18px] py-0.5 mr-2'} />
          {btnLabel}
        </IonButton>
      ) : (
        <IonButton onClick={setOpenModal} size="small" fill="outline" color="medium">
          <IonIcon icon={menuOutline} className="mr-2" slot="start" size="small"></IonIcon>
          {btnLabel}
        </IonButton>
      )
    )}

    {/* object form modal */}
    {showFormPopover ? (
      <div className="">
        {/* TODO */}
      </div>
    ) : null}

    {/* entity list and form */}
    {!isDisabled ? (
      <IonModal
        isOpen={showListPopover}
        onDidDismiss={onClosePopover}
        className="wide"
        initialBreakpoint={0.90}
        breakpoints={[0, 0.90]}
        backdropDismiss={true}
        backdropBreakpoint={0.5}
      >
        {/* list */}
        {/* <IonHeader className="">
          <IonSearchbar 
            value={textSearch}
            debounce={800}
            onIonInput={(e) => setTextSearch(e.target.value)}
            onClick={() => modalRef.current?.setCurrentBreakpoint(0.90)} placeholder="Search"
          />
        </IonHeader> */}

        <IonContent>
          {/* selected */}
          <IonHeader className="">
            <IonToolbar color={selectedDoc?.id ? 'medium' : ''} className="px-1.5 !pt-1.5 pb-1">
              <div slot="start">
                <IonTitle className="px-0">{title}</IonTitle>
                {selectedDoc ? (<>
                  <h3 className="px-1 pt-1 text-xs font-semibold uppercase mb-2">
                    <button type="button" onClick={() => { setSelectedDoc(null); onChange(null); }} className="relative -top-0.5 mr-2 inline-block w-4 h-4 rounded-full !bg-brand-secondary !text-brand-secondary-contrast font-normal text-center">
                      <div className="relative -mt-0.5">x</div>
                    </button>
                    Seleccionado
                  </h3>
                  <div className="mb-2 px-2 w-full rounded-md overflow-hidden border border-gray-200">
                    <Card color="medium" {...{ selectedDoc, doc: selectedDoc }} />
                  </div>
                </>) : null}
              </div>
              <div slot="end" className="flex place-content-end">
                {selectedDoc && isAllowed(entitySlug, ['update', 'instance:update']) ? (
                  <IonButton
                    color="light"
                    fill="solid"
                    onClick={() => setDocToUpdate(selectedDoc)}
                    size="small"
                  >
                    {/* <IonIcon icon={addOutline} size="small"></IonIcon> */}
                    <IonIcon icon={documentTextOutline} className="mr-1.5" size="small"></IonIcon>
                    Editar
                  </IonButton>
                ) : null}
                {isAllowed(entitySlug, ['create', 'instance:create']) ? (
                  <IonButton
                    color="secondary"
                    fill="solid"
                    onClick={() => setDocToUpdate({ id: 'new' })}
                    size="small"
                  >
                    {/* <IonIcon icon={addOutline} size="small"></IonIcon> */}
                    <IonIcon icon={documentTextOutline} className="mr-1.5" size="small"></IonIcon>
                    Agregar
                  </IonButton>
                ) : null}  
              </div>
            </IonToolbar>
          </IonHeader>

          {/* list */}
          <IonList className="mt-2 pb-96">
            <h3 className="px-2.5 text-gray-700 text-xs font-semibold uppercase mb-2">
              Opciones
            </h3>
            {/* entity form */}
            {docToUpdate && entitySlug ? (
              <RouteEntityCrudForm
                context={{
                  entitySlug,
                  docId: docToUpdate.id,
                  asModal: true,
                  onAfterSave: onAfterSaveForm,
                  onClose: onCloseForm,
                  defaultValue: defaultValueForm
                }}
                classes={{
                  loadingContainer: "!py-4"
                }}
              />
            ) : null}
            {isLoading ? (
              <div className="pt-32 flex place-content-center content-center items-center font-brand-main">
                <BadgeLoading className="text-brand-dark" />
              </div>
            ) : null}
            {docsListFiltered?.map(doc => (
              <IonItem 
                button
                key={doc.id}
                onClick={selectHandler(doc)}
                color={selectedDoc?.id === doc?.id ? 'light' : ''}
              >
                <Card color="light" {...{ selectedDoc, doc }} />
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    ) : null}
  </>);
};

export default RawInputSelectDocumentExtended;
