import _ from 'lodash';
import { RawInputObjectForm, RawInputSelectDocumentExtended } from '../../components/Form';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withPrefix } from '../instance/utilsInstance';
import { ObjectFormWrapped } from '../../components/Form/RawInputObjectForm';
import { objectFormToTaxonomyTypes } from '../entity/ObjectForm';


const invoiceCustomerFields = {
  bussinessName: {
    type: 'text',
    name: 'Nombre o razón social',
    required: true
  },
  bussinessRuc: {
    type: 'text',
    name: 'RUC',
    required: true
  },
  bussinessAddress: {
    type: 'textArea',
    name: 'Dirección'
  }
};

const requiredFields = [ 'bussinessName', 'bussinessRuc' ];

const RenderForm = (props) => {
  const { instance, value, onChange } = props;
  const [ contactId, setContactId ] = useState();
  const [ contactDoc, setContactDoc ] = useState();
  const [ casualData, setCasualData ] = useState(false);
  const [ hasErrors, setHasErrors ] = useState(false);
  const invoiceCustomerTaxonomyTypesDataList = useMemo(() => objectFormToTaxonomyTypes(invoiceCustomerFields), []);
  const modalApiRef = useRef();
  const formApiRef = useRef();

  useEffect(() => {
    if (_.size(value)) {
      // Asigna valores iniciales solo si `value` está definido y tiene contenido
      setContactId(value?.type === 'contact' ? value.contactId : null);
      setCasualData(value?.type === 'casual' ? value : null);
    } else {
      // Reinicia los valores si `value` está vacío
      setContactId(null);
      setContactDoc(null);
      setCasualData(null);
      formApiRef.current?.form.reset();
    }
  }, [value]);

  const assignContact = (contactId) => {
    setContactId(contactId);
    onChange({
      type: 'contact',
      contactId: contactId
    });
  };
  
  const assignCasualData = () => {
    modalApiRef.current?.close();
    onChange({
      type: 'casual',
      ...casualData
    });
    setContactId(null);
  }
  
  return (
    <div className="">
      <RawInputSelectDocumentExtended
        instance={instance}
        entitySlug={withPrefix(instance, 'contacts')}
        title="Contacto"
        btnLabel="Contacto"
        value={contactId}
        onChange={assignContact}
        onAfterChange={(value) => {
          setContactDoc(value); 
        }}
        className={inputClasses.fieldInput || ''}
      />

      <ObjectFormWrapped
        onFormChange={setCasualData}
        fieldsRequired={requiredFields}
        setHasErrors={setHasErrors}
        apiGetter={(api) => formApiRef.current = api}
      >
        <RawInputObjectForm
          value={casualData}
          showDataCard={value?.type === 'casual'}
          handleSubmit={assignCasualData}
          baseFieldName="customer"
          btnLabel="Casual"
          instance={instance}
          entitySlug={withPrefix(instance, 'contacts')}
          title="Casual"
          taxonomyTypesDataList={invoiceCustomerTaxonomyTypesDataList}
          fieldsRequired={requiredFields}
          showSaveButton={true}
          saveBtnLabel="Asignar"
          saveBtnColor="primary"
          disableBtnSave={hasErrors}
          apiGetter={(api) => modalApiRef.current = api}
        />
      </ObjectFormWrapped>
    </div>
  );
};

const RenderShow = (props) => {
  const { displayedValue } = props;

  console.log('customer', displayedValue);

  return (
    <div className="">
    </div>
  );
};

const dataTypeInvoiceCustomer = {
  RenderForm,
  RenderShow
};

export default dataTypeInvoiceCustomer;
