import _ from 'lodash';
import ObjectForm from '../entity/ObjectForm';


export const creditForeignField = {
  type: 'object',
  name: 'Configuración de crédito',
  fields: {
    creditAmount: {
      type: 'numberAmount',
      name: 'Monto del crédito'
    },
    paymentsQty: {
      type: 'number',
      name: 'Cuotas del crédito'
    },
    firstDueDate: {
      type: 'date',
      name: 'Día del primer vencimiento',
      // minDate: la fecha de contratación
      // maxDate: un día antes de la fecha del registro + 1 mes
    },
    paidAmount: {
      type: 'numberAmount',
      name: 'Entrega inicial',
      descriptionText: 'Se resta al monto del crédito para calcular los pagos'
    },
    paidQty: {
      type: 'number',
      name: 'Pagos ya realizados'
    }
  }
};

export const intervalFields = {
  interval: {
    type: 'select',
    name: 'Intervalo',
    options: [{
      value: 'days',
      label: 'Días'
    }, {
      value: 'weeks',
      label: 'Semanas'
    }, {
      value: 'months',
      label: 'Meses'
    }, {
      value: 'years',
      label: 'Años'
    }]
  },
  value: {
    type: 'number',
    name: 'Valor'
  }
};

export const creditDefaultFields = {
  punitiveInterest: {
    type: 'number',
    name: 'Interés moratorio',
    descriptionText: 'Interés aplicado al valor de la cuota una vez vencido y pasado el tiempo de gracia',
    helpText: 'Valor entre 0 y 1, ejemplo: 0.12'
  },
  interval: {
    type: 'collection',
    name: 'Tiempo entre vencimientos',
    fields: intervalFields
  },
  startStrategy: {
    type: 'select',
    name: 'Estrategia de la fecha del primer vencimiento',
    descriptionText: 'Utilizado para determinar el día del mes para los vencimientos',
    options: [{
      value: 'contractDate',
      label: 'Primer vencimiento el día de la contratación'
    }, {
      value: 'firstDueDateDelay',
      label: 'Primer vencimiento con retraso desde el día de la contratación'
    }, {
      value: 'customFirstDueDate',
      label: 'Primer vencimiento seleccionable por el usuario'
    }]
  },
  firstDueDateDelay: {
    type: 'collection',
    name: 'Tiempo para el primer vencimiento',
    fields: intervalFields
  },
  maxDueTime: {
    type: 'collection',
    name: 'Tiempo para Mora',
    descriptionText: 'Periodo máximo luego del vencimiento de la cuota antes de generar interés moratorio',
    fields: intervalFields,
  }
};

export const creditFields = {
  type: 'object',
  name: 'Configuración de crédito',
  fields: {
    calcMethod: {
      type: 'select',
      name: 'Método de cálculo',
      options: [{
        value: 'french',
        label: 'Método Francés'
      }, {
        value: 'german',
        label: 'Método Aleman'
      }]
    },
    compensatoryInterest: {
      type: 'number',
      name: 'Interés compensatorio',
      helpText: 'Valor entre 0 y 1, ejemplo: 0.12',
    },
    ...creditDefaultFields
  }
};

export const recurringFields = {
  type: 'object',
  name: 'Configuración de pago recurrente',
  fields: {
    ...creditDefaultFields
  }
};

const RenderForm = (props) => {
  let { 
    instance,
    entitySlug,
    fieldName,
    value,
    onChange,
    form,
    formApi
  } = props;

  const values = (formApi || form).getState().values;

  return (<>
    {/* {values?.paymentType === 'cash' ? () : null} */}

    {values?.paymentType === 'credit' ? (
      <ObjectForm
        instance={instance}
        entitySlug={entitySlug}  
        values={values}
        formApi={formApi || form}
        fields={{
          paymentConfig: creditFields
        }}
      />
    ) : null}

    {values?.paymentType === 'recurring' ? (
      <ObjectForm
        instance={instance}
        entitySlug={entitySlug}  
        values={values}
        formApi={formApi || form}
        fields={{
          paymentConfig: recurringFields
        }}
      />
    ) : null}
  </>);
};

const dataTypePaymentConfig = {
  RenderForm
};

export default dataTypePaymentConfig;