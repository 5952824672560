import _ from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { compileStringTemplate, lineBreaks } from '../../../libs/utils';
import { getImageURL } from '../../../libs/utils';
import { BtnsList, sectionCardBtnsFields } from "./BlockSectionCardBtns";
import { cardContainerStylesField } from "../parts/stylesFields";
import { CardContainer } from "../parts/PartCardContainer";
import { Text } from "../parts/PartText";
import { Card } from "../parts/PartCard";


export default function () {
  return {
    type: 'image',
    variant: 'imageExtended',
    template: CarouselWithExtendedImage,
    presets,
    params: {
      'bgStyles': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'cardContainerStyles': cardContainerStylesField,
      'titleStyles': {
        name: 'Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'subtitleStyles': {
        name: 'Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'contentStyles': {
        name: 'Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'btnListStyles': {
        name: 'Botones',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      }
    },
    // fields of text tab
    strings: {
      'items': {
        type: 'collection',
        name: 'Items del Carrusel',
        fields: {
          'images': {
            type: 'gallery',
            name: 'Imagenes',
            descriptionText: 'Se utilizara la primera imagen de la lista'
          },
          'title': {
            type: 'text',
            name: 'Título'
          },
          'subtitle': {
            type: 'text',
            name: 'Subtítulo'
          },
          'content': {
            type: 'textArea',
            name: 'Contenido'
          },
          'btnList': {
            type: 'collection',
            name: 'Botones',
            fields: sectionCardBtnsFields
          },
        }
      },
    },

    partsMap: [
      {
        'label': 'Bloque de contenido',
        'card': ['cardContainerStyles']
      },
      {
        'label': 'Items del Carrusel',
        'strings': ['items'],
      },
      {
        'label': 'Estilos del Título',
        'text': ['titleStyles'],
        'card': ['titleCardStyles']
      },
      {
        'label': 'Estilos del Subtítulo',
        'text': ['subtitleStyles'],
        'card': ['subtitleCardStyles']
      },
      {
        'label': 'Estilos del Contenido',
        'text': ['contentStyles'],
        'card': ['contentCardStyles']
      },
      {
        'label': 'Botones',
        'strings': ['btnList'],
        'card': ['btnListStyles']
      },
    ]
  };
};

// Función para procesar la URL de la imagen
const imgUrl = (imageSrc) => {
    let url = _.isArray(imageSrc) ? imageSrc[0] : imageSrc;
    url = _.startsWith(url, 'http') ? url : getImageURL(url, 'xl', null);
    return url;
  };

// Componente del Carrusel
const CarouselWithExtendedImage = (props) => {
  let {
    items,
    className,
    specDesign,
    cardContainerStyles,
    titleCardStyles,
    titleStyles,
    subtitleCardStyles,
    subtitleStyles,
    contentCardStyles,
    contentStyles,
    btnListStyles
  } = props;

  if (!items?.length) return null;

  return (
    <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay className={className}>
      {items.map((item, index) => (
        <div key={index} className="relative">
          {/* Imagen de fondo */}
          <img src={imgUrl(item.images)} alt={item.title} className="w-full h-screen object-cover md:object-top" />
          
          {/* Contenedor para el contenido sobre la imagen */}
          <CardContainer className="absolute inset-0 w-full" params={cardContainerStyles} specDesign={specDesign}>

            {/* Título */}
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
              <Text textParams={titleStyles} cardParams={titleCardStyles} specDesign={specDesign}>
                {lineBreaks(item.title)}
              </Text>
            </AnimationAddClassWhenVisible>

            {/* Subtítulo */}
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
              <Text textParams={subtitleStyles} cardParams={subtitleCardStyles} specDesign={specDesign}>
                {lineBreaks(item.subtitle)}
              </Text>
            </AnimationAddClassWhenVisible>
            
            
            {/* Contenido */}
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
              <Text textParams={contentStyles} cardParams={contentCardStyles} specDesign={specDesign}>
                {item.content}
              </Text>
            </AnimationAddClassWhenVisible>

            {item.btnList?.length ? (
              <Card params={btnListStyles} specDesign={specDesign}>
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
                  <BtnsList items={item.btnList} {...props} />
                </AnimationAddClassWhenVisible>
              </Card>
            ) : null}
          </CardContainer>

          {/* <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4 space-y-4">
          </div> */}
          
        </div>
      ))}
    </Carousel>
  );
};

const presets = {
  'base-card-centered': {
    "cardContainerStyles": [
      {
        "round": "2xl",
        "padding": 4,
        "screen": "xxs",
        "marginY": 16,
        "marginX": 10,
        "cardWidth": "fit",
        "shadow": "lg",
        "bgParams": {
          "type": "color",
          "bgVideoUrl": "",
          "bgOverlayAlpha": 0,
          "bgColor": "#ffffff"
        }
      },
      {
        "marginX": 12,
        "marginY": 32,
        "screen": "md"
      },
      {
        "cardWidth": "1/3",
        "padding": 6,
        "screen": "lg"
      },
    ],
    "titleStyles": [
      {
        "textAlign": "center",
        "textSize": "3xl",
        "font": "main",
        "screen": "xxs",
        "textColor": "primary",
        "fontVariations": [
          "bold"
        ]
      },
      {
        "textSize": "4xl",
        "screen": "md"
      },
      {
        "screen": "2xl",
        "textSize": "5xl"
      }
    ],
    "subtitleStyles": [
      {
        "textColor": "#000000",
        "font": "secondary",
        "textAlign": "center",
        "screen": "xxs",
        "textSize": "xl"
      },
      {
        "screen": "md",
        "textSize": "2xl"
      },
      {
        "textSize": "3xl",
        "screen": "2xl"
      }
    ],
    "contentStyles": [
      {
        "screen": "xxs",
        "fontVariations": [
          "normal"
        ],
        "textAlign": "center",
        "font": "secondary",
        "textColor": "#000000",
        "textSize": "base"
      }
    ],
    "btnListStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ]
  },
};