import _ from 'lodash';
import { assignDeep, stackClasses } from "../../libs/utils";
import { getPrefix, withOutPrefix, withPrefix } from '../../modules/instance/utilsInstance';


export const processRenderProps = (props) => {
  let { 
    fieldName,
    taxonomyType,
    taxonomyTypeDoc,
    values,
    // onChange, // TODO remove, debe ser onAfterChange, onChange esta reservado para RawInputs debido a que los dataTypes utilizan input.onChange del form
    fieldsRequired,
    paramsByFieldSlug,
    isFilter
  } = props;

  const taxonomyTypeData = taxonomyType?.data || taxonomyTypeDoc?.data || taxonomyType;
  const title = taxonomyTypeData?.name;
  const _fieldName = fieldName || taxonomyTypeData?.fieldName || taxonomyTypeData?.nameSlug;
  
  let overrideParams = { ...props.overrideParams };
  if (paramsByFieldSlug && paramsByFieldSlug[_fieldName]) {
    overrideParams = _.clone(overrideParams);
    overrideParams = assignDeep(overrideParams, paramsByFieldSlug[_fieldName]);
  }

  const param = {
    ...taxonomyTypeData,
    ...taxonomyTypeData?.param,
    ...taxonomyTypeData?.style,
    ...overrideParams,
    title,
    fieldsRequired,
    disabled: isFilter ? false : (overrideParams?.disabled || taxonomyTypeData?.readOnly),
    show: taxonomyTypeData?.show
  };
  let style = param?.style || {};
  const design = props?.design || style?.design || param?.design || 'default';
  param.classes = stackClasses(props?.classes, style?.classes, taxonomyTypeData?.classes);
  param.instance = props.instance || taxonomyTypeData?.instance;
  let entitySlug = param?.entityNameSlug || param?.entitySlug || props?.entitySlug;
  entitySlug = getPrefix(entitySlug) === 'instance' ? withPrefix(param.instance, withOutPrefix(entitySlug)) : entitySlug;
  param.entitySlug = entitySlug;
  
  return {
    entitySlug,
    taxonomyTypeData,
    title,
    _fieldName,
    param,
    style,
    design
  }
};