import { useMemo, useState } from 'react';
import _ from 'lodash';
import { EntityTaxonomyForm, inputClasses } from '../EntityTaxonomyForm';
import { IonButton, IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton as IonButtonIonic, IonIcon } from '@ionic/react';
import ObjectShow from '../../modules/entity/ObjectShow';
import { viewInlineClasses } from '../../modules/entity/docCardsVariants';
import { Form } from 'react-final-form';
import FormChangeSubscriber from './FormChangeSubscriber';
import IonBtnLoading from '../ui/IonBtnLoading';
import { 
  menuOutline
} from 'ionicons/icons';


export const ObjectFormWrapped = (props) => {
  let {
    children,
    onSave,
    onValidation,
    onFormChange,
    defaultValue,
    doc,
    fieldsRequired,
    setHasErrors,
    apiGetter
  } = props;

  const handleSubmit = async (values) => {
    if (onSave) {
      await onSave(values);
    }
  };

  const validateForm = (values) => {
    const errors = {};
    // all required fields
    fieldsRequired?.forEach(field => {
      if (!values[field]) {
        errors[field] = ' ';
      }
    });
    onValidation && onValidation(values, errors);
    setHasErrors && setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };

  const renderContent = ({ form, values, errors, handleSubmit, submitting }) => {
    apiGetter && apiGetter({
      form,
      values,
      errors,
      handleSubmit,
      submitting
    });

    return (<>
      {onFormChange ? (
        <FormChangeSubscriber doc={doc} onFormChange={onFormChange} />
      ) : null}
      <form 
        onSubmit={(event) => {
          // TODO: prevent submit on pressing enter on a field
          event.preventDefault(); // No funciona
        }}
      >
        {children}
      </form>
    </>);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={doc ? (doc.data || doc) : defaultValue}
      validate={validateForm}
      render={renderContent}
    />
  );
};

const RawInputObjectForm = (props) => {
  let {
    instance,
    entitySlug,
    title,
    baseFieldName,
    showDataCard = true,
    showSaveButton = false,
    saveBtnLabel,
    saveBtnColor,
    disableBtnSave = false,
    handleSubmit,
    name,
    value,
    fields,
    taxonomyTypesDataList,
    fieldsRequired,
    apiGetter
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  
  const fieldsPrefixed = useMemo(() => {
    if (!fields && taxonomyTypesDataList) {
      return taxonomyTypesDataList;
    }
    if (!baseFieldName && !name) {
      return fields;
    }
    return _.map(fields, (field, objFieldName) => {
      let innerName = _.isString(objFieldName) ? objFieldName : field.fieldName;
      return {
        ...field,
        fieldName: `${baseFieldName || name}[ ${innerName} ]`
      };
    });
  }, [fields]);

  apiGetter && apiGetter({
    isOpen,
    close: () => setIsOpen(false)
  });

  return (
    <div className="space-y-2">
      {_.size(value) && showDataCard ? (
        <div className="px-2 rounded-md overflow-hidden border border-gray-200">
          <div className={`flex flex-row py-2 items-stretch w-full`}>
            <ObjectShow
              instance={instance}
              entitySlug={entitySlug}
              value={value}
              fields={fields}
              taxonomyTypesDataList={fieldsPrefixed}
              design="flat"
              classes={viewInlineClasses}
            />
          </div>
        </div>
      ) : null}
      <IonButton onClick={() => setIsOpen(true)} size="small" fill="outline" color="medium">
        <IonIcon icon={menuOutline} className="mr-2" slot="start" size="small"></IonIcon>
        {title}
      </IonButton>
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start">{title}</IonTitle>
            <IonButtons slot="end">
              <IonButtons>
                <IonButtonIonic onClick={() => setIsOpen(false)}>Cerrar</IonButtonIonic>
              </IonButtons>

              {showSaveButton ? (
                <IonBtnLoading
                  label={saveBtnLabel || 'Guardar'}
                  size="small"
                  fill="solid"
                  color={saveBtnColor || 'primary'}
                  onClickAsync={handleSubmit}
                  disabled={disableBtnSave}
                />
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="p-2 mb-64">
            <EntityTaxonomyForm
              fieldsRequired={fieldsRequired}
              taxonomyTypesDataList={fieldsPrefixed}
              classes={inputClasses}
            />
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default RawInputObjectForm;