import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import Select from 'react-select';


// Componente para personalizar cada opción
export const CustomOptionCountry = (props) => {
  const { data, innerRef, innerProps, isSelected } = props;

  return (
    <div 
      ref={innerRef} 
      {...innerProps} 
      className={`p-2 w-full cursor-pointer flex flex-row items-center place-content-start gap-2 ${isSelected ? 'bg-gray-100' : 'bg-white'}`}
    >
      <span className="react-tel-input space-x-1 text-xs w-20">
        <span className={`flag inline-block -mt-1 ${data.iso2?.toLowerCase()}`}></span>
        <span className="">{data.currency_symbol}</span>
        <span className="">{data.currency}</span>
      </span>

      {/* Label principal del país */}
      <span className="font-semibold text-lg">{data.label}</span>
    </div>
  );
};

// Componente predeterminado
const CustomOption = (props) => {
  const { data, innerRef, innerProps, isSelected } = props;

  return (
    <div ref={innerRef} {...innerProps} style={{
      backgroundColor: isSelected ? 'lightgray' : 'white',
      padding: '10px',
      cursor: 'pointer'
    }}>
      {data.label}
    </div>
  );
};

const FormFieldSelect = (props) => {
  let {
    name,
    options,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    onSelect = (() => null),
    multiple = false,
    optionRender = null // Nueva propiedad para personalización
  } = props;

  const validateField = (value) => {
    let error;

    if (fieldsRequired?.includes(name)) {
      if (multiple) {
        if (!value || value.length === 0) {
          error = 'Debes seleccionar al menos una opción';
        }
      } else {
        if (value === undefined || value === '') {
          error = 'Este campo es obligatorio';
        }
      }
    }

    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }

    return error;
  };

  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => {
          const onChange = (value) => {
            if (multiple) {
              let selectedValues = [];
              value?.forEach((option) => {
                selectedValues.push(option.value);
              });
              if (!_.size(selectedValues)) {
                selectedValues = null;
              }
              input.onChange(selectedValues);
              onSelect && onSelect(selectedValues);
            } else {
              input.onChange(value?.value);
              onSelect && onSelect(value?.value);
            }
          };
          
          let value;
          if (multiple) {
            value = _.map(input.value, (field) => {
              return _.find(options, option => option.value === field);
            });
          }
          else {
            value = _.find(options, option => option.value === input?.value || option.value === input?.value?.value);
          }

          let components = {
            Option: optionRender || CustomOption, // Usar el renderer externo o el componente por defecto
          };

          if (optionRender) {
            components.SingleValue = optionRender;
          }

          return (<>
            <FieldLabel {...props} input={input} meta={meta} />
            <div className="">
              <Select
                options={options}
                isMulti={multiple}
                placeholder={`Seleccionar`}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: 'lightgray',
                    primary: 'darkgray',
                  },
                })}
                value={value}
                onChange={onChange}
                isDisabled={disabled}
                menuPortalTarget={document.body}
                components={components}
              />
            </div>
            <ShowFieldHelp {...props} name={input?.name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
          </>)
        }}
      </Field>
    </div>
  );
};

export default FormFieldSelect;
