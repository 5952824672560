import { useEntityFullBySlug } from '../modules/entity/Entity';
import { typeConfigs } from "../components/DataTypes";
import { assignDeep } from '../libs/utils';
import { Fragment, useMemo } from 'react';
import { useAuth } from '../modules/user/AuthContext';
import config from '../config';
import { usePartOfModule } from './Module/PartOfModule';
import { useModule } from '../libs/ModuleContext';


export const inputClasses = {
  renderListContainer: 'flex flex-wrap gap-6',
  // renderItemContainer: '',
  fieldContainer: 'text-left flex-grow basis-full w-full',
  fieldLabel: 'block font-bold text-xs text-black uppercase mb-1 flex flex-row place-content-between items-center h-6',
  fieldInput: 'block w-full'
};

const Render = (props) => {
  const typeToUse = props.taxonomyType?.type || props.taxonomyType?.data?.type;
  const typeConfig = typeConfigs[typeToUse];
  if (!typeConfig || !typeConfig.Render) {
    return null;
  }
  return typeConfig.Render(props);
};

const RenderTaxonomyTypeField = (props) => {
  let {
    id,
    data,
    taxonomyTypeDoc,
    instance,
    entitySlug,
    entityDoc,
    overrideParams,
    only,
    exclude,
    userAuth
  } = props;
  entitySlug = entitySlug || entityDoc?.data.entitySlug;
  let fieldName = data?.fieldName || data?.nameSlug;
  const FormAppendField = usePartOfModule({
    type: "crud",
    action: "FormAppend-" + fieldName,
    entitySlug
  });
  const FormPrependField = usePartOfModule({
    type: "crud",
    action: "FormPrepend-" + fieldName,
    entitySlug
  });
  if (!fieldName) {
    return null;
  }
  if (data?.deleted || data?.hiddeInForms || data?.hidden) { return null; }
  if (only?.length && !only?.includes(data?.nameSlug)) { return null; }
  if (exclude?.length && exclude?.includes(data?.nameSlug)) { return null; }
  overrideParams = { ...overrideParams };
  if (props.paramsByFieldSlug && props.paramsByFieldSlug[data?.nameSlug]) {
    overrideParams = assignDeep({}, overrideParams, props.paramsByFieldSlug[data.nameSlug]);
  }

  // restrict by moderators only
  if (
    data.forMainModerator
    && userAuth?.userDoc && userAuth?.rolesDoc
    && userAuth.rolesDoc?.data.nameSlug !== config.modules.user.userTopRoleSlug
  ) {
    return null;
  }

  return (
    <>
      {FormPrependField ? (<FormPrependField {...props} />) : null}
      <Render  
        {...props}
        taxonomyType={taxonomyTypeDoc || data}
        overrideParams={overrideParams}
        entitySlug={entitySlug}
      />
      {FormAppendField ? (<FormAppendField {...props} />) : null}
    </>
  );
};

export const EntityTaxonomyForm = (props) => {
  const {
    taxonomyTypesDocList,
    taxonomyTypesDataList
  } = props;
  const { isAllowed } = useModule();
  return (
    <div className={props?.classes?.renderListContainer}>
      {taxonomyTypesDocList?.map((taxonomyTypeDoc) => {
        const { id, data } = taxonomyTypeDoc;
        return <RenderTaxonomyTypeField {...props} key={id} {...{ id, data, taxonomyTypeDoc, isAllowed }} />
      })}
      {taxonomyTypesDataList?.map((taxonomyTypeData, index) => {
        return <RenderTaxonomyTypeField {...props} key={index} {...{ id: index, data: taxonomyTypeData, isAllowed }} />
      })}
    </div>
  );
};

const useEntityTaxonomyForm = ({ instance, entitySlug, fieldsRequired=[], setFieldsRequired }) => {
  const { userAuth } = useAuth();
  
  const onFetch = ({ taxonomyTypesDocList }) => {
    const addFieldsRequired = [];
    taxonomyTypesDocList.forEach((taxonomyType) => {
      if (taxonomyType.data.required && !taxonomyType.data.deleted) {
        addFieldsRequired.push(taxonomyType.data.nameSlug);
      }
    });
    setFieldsRequired([ ...addFieldsRequired ]);
  }
  
  const entitySpecs = useEntityFullBySlug({ entitySlug, onFetch });
  const { taxonomyTypesDocList } = entitySpecs;
  const EntityTaxonomyFormMemo = useMemo(() => {
    return (props) => {
      return (
        <EntityTaxonomyForm 
          {...props}
          instance={instance}
          taxonomyTypesDocList={props.taxonomyTypesDocList || taxonomyTypesDocList} 
          fieldsRequired={fieldsRequired}
          setFieldsRequired={setFieldsRequired}
          userAuth={userAuth}
        />
      )
    };
  }, [taxonomyTypesDocList]);

  return {
    EntityTaxonomyForm: EntityTaxonomyFormMemo,
    ...entitySpecs
  };
}

export default useEntityTaxonomyForm;