import { processRenderProps } from './dataTypeUtils';
import PartOfModule from '../Module/PartOfModule';
import {
  FormFieldCustom
} from '../Form';
import { useMemo } from 'react';

export default {
  Render(props) {
    const {
      entitySlug,
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    console.log('coded', props)
    const RenderForm = taxonomyTypeData?.RenderForm;
    const RenderFormMemo = useMemo(() => ({ value, onChange }) => {
      return (
        RenderForm ? (
          <RenderForm
          {...props}
          {...{value, onChange, title, taxonomyTypeData, param }}
          />
        ) : (
          <PartOfModule
            type="codedDataType"
            fieldName={_fieldName}
            action="RenderForm"
            entitySlug={entitySlug}
            param={{ ...props, fieldName: _fieldName, value, onChange, title, taxonomyTypeData, param }} 
          />
        )
      );
    }, []);
    
    return (
      <FormFieldCustom
        {...props}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        Render={RenderFormMemo}
        {...param}
        name={_fieldName}
      />
    );
  },

  RenderInputParams: (props) => {
    let { values, taxonomyTypeDoc, entitySlug } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (<>
      <PartOfModule
        type="codedDataType"
        fieldName={_fieldName}
        action="RenderInputParams"
        entitySlug={entitySlug}
        param={{ values, title, taxonomyTypeData, param, formApi: props?.formApi }} 
      />
    </>);
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (null),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}