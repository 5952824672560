import _ from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useEntityFullBySlug } from '../entity/Entity';
import { CartProvider, useCart } from "./useCartData";
import { stringifyAttrValParams, trimSlash, withProvider } from "../../libs/utils";
import { useScrollUtils } from "../panel/useScrollUtils";
import { CartItemThumbGridMemo } from "./CartItemThumbGrid";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { urlPrefix } from "../instance/utilsInstance";
import { ProductViewRawMemo } from "./BlockProductView";
import { useCategoryFields } from "./useCategoryFields";
import { usePrevious } from "../../libs/usePrevious";
import config from "../../config";


export const imgProportionField = {
  type: 'select',
  name: 'Proporcion de imagen',
  options: [{
    label: 'Definido en el branding',
    value: 'brand'
  }, {
    label: 'Rectangulo',
    value: 'vertical-square'
  }, {
    label: 'Rectangulo vertical extenso',
    value: 'vertical-wide'
  }],
  defaults: 'vertical-square'
};


export default function () {
  return {
    type: 'itemsSelectedGrid',
    variant: 'mainItems',
    template: BlockItemsSelectedGrid,
    params: {
      filterPath: {
        type: 'text',
        defaultValue: '/m/items'
      },

      'imgProportion': imgProportionField
    },
    strings: {
      itemsIds: {
        type: 'selectManyEntityDocument',
        name: 'Items seleccionados',
        param: {
          entitySlug: `instance.${config.modules.cart.cartItemEntitySlug}`
        }
      }
    }
  };
};

export const BlockItemsSelectedGrid = withProvider(BlockItemsSelectedGridRaw, CartProvider);

function BlockItemsSelectedGridRaw(props) {
  let {
    specDesign,
    instance,
    data,
    history,
    parsedParams,
    isAllowed,
    isMinBreakpointActive,
    match,
    location,
    selectedItemsIds,
    filterPath,
    imgProportion = 'vertical-square'
  } = props;

  const blockId = useMemo(() => (_.uniqueId('item-grid')), []);
  const topElementRef = useRef();
  const { elementToScrollRef } = useScrollUtils();

  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();

  const variantsEntitySlug = entityMap.cartItemVariants.entitySlug;
  const mainsEntitySlug = entityMap.cartItems.entitySlug;
  const entitySpecsVariants = useEntityFullBySlug({ entitySlug: variantsEntitySlug });
  const entitySpecsMains = useEntityFullBySlug({ entitySlug: mainsEntitySlug });
  const [ isLeaving, setIsLeaving ] = useState(false);
  const { filterMenuTaxonomyTypes } = entitySpecsVariants;
  const { categoryFieldsDocList, categoryFieldsById } = useCategoryFields({ instance });
  const [ itemsDocs, setItemsDocs ] = useState([]);

  const fullItemTaxonomyTypes = useMemo(() => {
    if (entitySpecsVariants?.taxonomyTypesDocList && entitySpecsMains?.taxonomyTypesDocList) {
      return [ ...entitySpecsMains.taxonomyTypesDocList, ...entitySpecsVariants.taxonomyTypesDocList ];
    }
    return [];
  }, [entitySpecsMains, entitySpecsVariants]);

  const [ loading, setLoading ] = useState(true);
  const [ emptyResults, setEmptyResults ] = useState(false);

  const { cartId, mainItemId, variantItemId, variantParams, queryParams } = useMemo(() => {
    let { cartId, main, variant, params, ...queryParams } = parsedParams || {};
    return {
      cartId,
      mainItemId: main,
      variantItemId: variant,
      variantParams: params,
      queryParams
    }
  }, [parsedParams]);

  const prevQueryParams = usePrevious(queryParams);

  // useEffect(() => {
  //   fetchFilteredDocs();
  // }, []);

  // const fetchFilteredDocs = async (queryParams, page) => {
  //   setLoading(true);


  //   selectedItemsIds

  //   setItemsDocs


  //   // parse data and remove {main, variant}
  //   const filterQuery = filterDataToQueryFormatter(queryParams, filterTaxonomyTypesByCategory); 
  //   // hard filters
  //   filterQuery.mainAvailable = 'true';
  //   filterQuery.variantAvailable = 'true';
  //   filterQuery.deleted = 'false';
  //   setCurrentQueryString(JSON.stringify(filterQuery));
  //   let fetchedPages = await ItemQueryLibrary.filterAndGetPages(filterQuery, {
  //     page: page,
  //     limit: perPage,
  //     // orderBy: { field: 'createdAt', direction: 'desc' },
  //     onFetch(results) {
  //       // inherit and overwrite data 
  //       results.forEach((doc) => {
  //         doc.data.name = doc.data.variantName || doc.data.name;
  //         doc.data.price = doc.data.variantPrice || doc.data.price;
  //       });
  //       return results;
  //     }
  //   });
  //   setEmptyResults(fetchedPages.length === 1 && fetchedPages[0].length === 0);
  //   setPages(fetchedPages);
  //   setLoading(false);
  // };
  
  const goToItemDetails = useCallback(({ mainId, variantId, params }) => {
    setIsLeaving(true);
    let route = urlPrefix(instance, `${trimSlash(filterPath)}/`);
    route = `${route}/#/${stringifyAttrValParams({
      ...queryParams,
      main: mainId,
      variant: variantId,
      params: _.omit(params, ['variantId'])
    })}`;
    history.push(route);  
  }, [queryParams, parsedParams]);

  const closeItemDetails = () => {
    setIsLeaving(false);
    let route = urlPrefix(instance, `${trimSlash(filterPath)}/`);
    if (_.size(queryParams)) {
      route = `${route}/#/${stringifyAttrValParams({
        ...queryParams
      })}`;
    } else {
      route = `${route}/`;
    }
    history.push(route);
    // small scroll to refresh menu visibility
    if (elementToScrollRef.current?.detil?.currentY) {
      elementToScrollRef.current.scrollToPoint(0, elementToScrollRef.current?.detil?.currentY - 1, 100);
    }
  };

  const onShow = (doc) => {
    goToItemDetails({ mainId: doc.data.mainItemId, variantId: doc.id });
  };

  if (entitySpecsVariants.isLoading || entitySpecsMains.isLoading || !categoryFieldsDocList) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  if (emptyResults) {
    return null;
  }

  return (
    <div id={blockId} className={`container-width-wide pb-8 lg:pt-4 lg:px-2 flex flex-col`}>
      <div ref={topElementRef} className="relative -top-14 lg:-top-16"></div>

      <div className={`lg:py-2.5 w-full px-4 lg:px-2`}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 items-start">
          <CartItemThumbGridMemo
            items={itemsDocs}
            {...{ onShow, ...props }}
            entitySpecs={{
              entitySlug: variantsEntitySlug,
              taxonomyTypesDocList: fullItemTaxonomyTypes
            }}
            classes={{ imgProportion }}
            renderAsGrid={false}
            categoryFieldsDocList={categoryFieldsDocList}
            categoryFieldsById={categoryFieldsById}
          />
        </div>
      </div>

      {/* Item details */}
      {fullItemTaxonomyTypes?.length ? (
        <ProductViewRawMemo
          {...props}
          {...{
            mainItemId,
            variantItemId,
            variantParams,
            categoryFieldsById,
          }}
          variantsEntitySpecs={entitySpecsVariants}
          mainsEntitySpecs={entitySpecsMains}
          design="modal"
          goToItemByParams={goToItemDetails}
          dismissModal={closeItemDetails}
          showCurrentCart={false}
        />
      ) : null}
    </div>
  );
}